<template>
  <div
    :class="[
      'menu-section mb-4',
      sectionPxClass,
      { isDirty },
      { subsection: isSubsection },
      entity.section_type ? `type--${entity.section_type}` : '',
    ]"
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <slot>
      <div class="menu-section--top mb-3">
        <BaseEditable
          v-if="original.title || isShowingEditUI"
          ref="title"
          tag="span"
          :content="original.title"
          :mods="myMods.title"
          placeholder="Add Section Title"
          class="menu-section--title montserrat--font"
          @update="updateSectionAction('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <div v-if="entity.section_subtitle_note" class="menu-section--subtitle">
          <template v-if="entity.section_subtitle_note[0] === '{'">
            <component
              :is="getTagForElem(elem.type)"
              v-for="elem of JSON.parse(entity.section_subtitle_note).content"
              :key="JSON.stringify(elem.text)"
              >{{ elem.text }}</component
            >
          </template>
          <BaseEditable
            v-else
            tag="span"
            :content="original.section_subtitle_note"
            :mods="myMods.section_subtitle_note"
            @update="updateSectionAction('section_subtitle_note', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </div>
      </div>
      <div class="menu-section--items">
        <BaseButton
          v-if="!itemIds.length && isShowingEditUI"
          md-icon="add"
          text-color="green"
          color="green"
          small
          round
          outline
          @click="stageNewItem({ parentId: entity.id })"
          >Add an Item</BaseButton
        >
        <BaseMenuComponent
          v-else
          name="MenuSectionItems"
          :item-ids="itemIds"
          :section="entity"
          item-class="blue-giant--menu-item montserrat--font"
          @addItem="addItemAction({ sectionId: entity.id, index: $event })"
          @deleteSectionItem="
            removeItemAction({ sectionId: entity.id, itemId: $event.id })
          "
          @itemUpdated="updateItemAction(entity.id, $event)"
          @dragEvent="dragEventAction"
        />
      </div>
      <div v-if="subsections.length" class="menu-section--sub-menus">
        <WithMenuSection
          v-for="subsection of subsections"
          :id="subsection.id"
          v-slot="{
            isSectionLoading: isSubsectionLoading,
            section: theSubsection,
            itemMods: subsectionItemMods,
            myMods: subsectionMods,
          }"
          :key="subsection.id"
        >
          <BaseMenuComponent
            :is="subsection.is_callout ? 'MenuSectionCallout' : 'MenuSection'"
            :item-mods="subsectionItemMods"
            :my-mods="subsectionMods"
            :is-subsection="true"
            :entity="theSubsection"
            @updateTitle="
              updateSectionAction('title', $event, theSubsection.id)
            "
            @updateSubtitle="
              updateSection('section_subtitle_note', $event, theSubsection.id)
            "
            @dirtyUpdate="adjustDirtScore"
          />
        </WithMenuSection>
      </div>
      <!-- <EntityModButtons
      v-if="isHovering && isShowingEditUI"
      class="section-buttons--wrap"
      bottom
      add-btn-color="blue"
      entity-name="Section"
      show-delete
      :hide-buttons="isSelected && !isHovering"
      @add="$emit('addSectionAfter')"
      @delete="$emit('deleteSection')"
    /> -->
    </slot>
  </div>
</template>

<script>
import MenuSectionMixin from '@mixins/menu-section'

import MenuItem from '@menu/MenuItem'
import MenuSectionCallout from '@menu/MenuSectionCallout'
import EntityModButtons from '@menu/EntityModButtons'

export default {
  name: 'BlueGiantMenuSection',
  components: { MenuItem, MenuSectionCallout, EntityModButtons },
  mixins: [MenuSectionMixin],
  computed: {
    sectionPxClass() {
      if (this.isSubsection || this.$mq === 'xs') {
        return ''
      }
      let px = ''
      switch (this.$mq) {
        case 'sm':
        case 'md':
          px = 'px-3'
          break
        case 'lg':
        case 'xl':
          px = 'px-5'
          break
        default:
          break
      }
      return px
    }, // sectionPxClass
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@src/design/themes/BlueGiant.scss';

$description-font-size: 1.15em;

.menu-section {
  position: relative;
  &.subsection {
    margin-top: 50px;
  }
  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
}

.menu-section--top {
  margin-top: 0.5em;
}

.menu-section--title {
  margin-bottom: 0.1em;
  font-size: $description-font-size * 2;
  font-weight: 300;
  line-height: 0.7;
  letter-spacing: 0.038em;

  @media (max-width: 600px) {
    position: sticky;
    top: 0;
    background-color: rgb(250, 250, 250);
  }
}

.menu-section--subtitle {
  font-size: 1.15rem;
  font-weight: 500;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.blue-giant--menu-item {
  .menu-item--title,
  .item-price {
    font-size: $description-font-size * 1.3;
    font-weight: 800 !important;
    line-height: 1.2 !important;
  }

  .menu-element--dots {
    height: $description-font-size * 1.3;
    border-width: 1px;
  }

  .menu-item--description {
    @extend .karla--font;

    font-size: $description-font-size;
    line-height: 1.3;
    letter-spacing: 0.02em;
  }
}
</style>
