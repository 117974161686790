<template>
  <VLayout
    v-on-clickaway="clickawayFn"
    :class="[
      'menu-item',
      `type--${sectionType}`,
      !isCondensed ? 'standard' : 'condensed',
      { hovering: isHovering },
      { 'showing-ui': isShowingEditUI },
      { selected: isSelected && isShowingEditUI },
      { 'is-dirty': entity.isNew },
      { trashed: isTrashed },
      { 'delete-hover': deleteHover },
    ]"
    row
    wrap
    :[itemMargin]="true"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @click="itemClickAction"
  >
    <EntityModButtons
      v-show="showHoverControls"
      :hide-buttons="isSelected && !isHovering"
      :is-entity-selected="isSelected"
      @add="$emit('addItemBefore')"
    />
    <VFlex xs12>
      <VLayout class="menu-item--top" row>
        <slot
          name="title"
          :emitItemUpdated="emitItemUpdated"
          :content="original.title"
          :mods="myMods.title"
          :dirtyUpdate="adjustDirtScore"
        >
          <MenuItemTitle
            ref="selectedOnMountTarget"
            :content="original.title"
            :mods="myMods.title"
            @update="emitItemUpdated('title', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </slot>

        <slot name="dots">
          <VFlex
            v-if="prices.length"
            class="menu-element--dots"
            grow
            mx-1
          ></VFlex>
        </slot>
        <slot
          name="prices"
          :prices="prices"
          :priceMods="priceMods"
          :emitItemUpdated="emitItemUpdated"
          :adjustDirtScore="adjustDirtScore"
        >
          <MenuItemPrices
            v-if="allowPrices"
            :prices="prices"
            :mods="priceMods"
            @update="emitItemUpdated('prices', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </slot>
      </VLayout>
    </VFlex>
    <template v-if="allowDesc">
      <slot
        v-if="original.description || descMods || isSelected"
        name="desc"
        :original="original"
        :descMods="descMods"
        :isSelected="isSelected"
        :adjustDirtScore="adjustDirtScore"
        :emitItemUpdated="emitItemUpdated"
        :descProps="descProps"
      >
        <MenuItemDescription
          ref="item-desc"
          :content="
            [null, undefined].includes(original.description)
              ? ''
              : original.description
          "
          :mods="descMods"
          :is-item-selected="isSelected"
          :placeholder="descPlaceholder"
          v-bind="descProps"
          @update="emitItemUpdated('description', $event)"
          @dirtyUpdate="adjustDirtScore"
          @editableDestroyed="$emit('dirtyUpdate', false)"
        />
      </slot>
    </template>
    <BaseMenuComponent
      v-if="addons.length || (isSelected && isShowingEditUI)"
      ref="item-addons"
      name="MenuItemAddons"
      :is-item-selected="isSelected"
      :is-dirty="areAddonsModified"
      :addons="addons"
      @newAddon="newAddonAction"
      @removeAddon="removeAddonAction"
      @updateAddon="updateAddonAction"
    />
    <MenuItemAttrs
      v-if="allowAttrs"
      :attr-ids="entity.attributes"
      :is-dirty="areAttsModified"
      :is-item-selected="isSelected"
      @selectAttribute="selectAttributeAction"
      @deselectAttribute="deselectAttributeAction"
    />
    <slot name="bottom-separator"></slot>

    <EntityModButtons
      v-show="showHoverControls"
      show-delete
      bottom
      :hide-buttons="isSelected && !isHovering"
      :is-entity-selected="isSelected"
      @deleteBtnClick="$emit('deleteItem')"
      @add="$emit('addItemAfter')"
      @deleteHover="deleteHover = $event"
    />
    <VLayout v-show="showHoverControls" class="drag-handle--wrap">
      <VIcon class="drag-handle" color="grey">drag_indicator</VIcon>
    </VLayout>
  </VLayout>
</template>

<script>
import { ENTITY_LOOKUP } from '@constants/lookupTables'
import { mixin as clickaway } from 'vue-clickaway'
// import selectedOnMountMixin from '@mixins/selected-on-mount'
import MenuItemMixin from '@mixins/menu-item'
import GetsDirty from '@mixins/gets-dirty'
import _get from 'lodash/get'

export default {
  name: 'MenuItemShell',
  components: {},
  mixins: [
    MenuItemMixin,
    clickaway,
    // selectedOnMountMixin('entity.isNew'),
    GetsDirty(ENTITY_LOOKUP.MenuItem),
  ],
  props: {},
  data() {
    return {}
  },
  computed: {
    itemMargin() {
      return this.isCondensed ? 'py-0' : 'py-1'
    }, // itemMargin
    descPlaceholder() {
      let orig = _get(this.original, 'description', '')
      return this.entity.isNew
        ? 'Description'
        : orig !== null && orig.length
        ? 'Description Deleted'
        : 'Add Description'
    }, // descPlaceholder
  }, // computed
}
</script>

<style lang="scss">
@use "sass:math";
@import '@design';

$hover-border-w: 2px;
.menu-item {
  position: relative;
  transition: background-color 0.2s ease, transform 0.2s ease;
  &.standard {
    .menu-item--top {
      > * {
        line-height: 1;
      }
    }
  }

  .menu-item--title,
  .menu-item--prices {
    z-index: 920;
    letter-spacing: 0.02em;
  }
  .menu-item--description {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.3;
  }
  &.sortable-ghost {
    background-color: rgba($color-db-green, 0.2);
    opacity: 0.4;
  }
  &.showing-ui {
    &.hovering,
    &.selected {
      $gutter: 20px;

      padding-right: $gutter;
      padding-left: math.div($gutter, 2);
      margin-right: -$gutter;
      margin-left: math.div(-$gutter, 2);
    }
  }
  &.hovering.showing-ui {
    background-color: rgba($color-db-teal, 0.5);
  }
  &.selected {
    background-color: rgba($color-db-green, 0.1);
  }
  &.delete-hover {
    background: rgba(red, 0.075) !important;
  }
  .drag-handle--wrap {
    position: absolute;
    top: 50%;
    right: 0;
    min-width: 20px;
    height: 100%;
    transform: translateY(-50%);
    .drag-handle {
      font-size: 18px;
      cursor: pointer;
    }
  }
} // menu-item

.menu-element--dots {
  border-bottom: 2px dotted #000;
}
</style>
