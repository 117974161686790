import { mapActions, mapGetters } from 'vuex'
import arrayMove from 'array-move'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import WithMenuSection from '@dataProviders/WithMenuSection'
import { ENTITY_LOOKUP, ENTITY_TYPES } from '@constants/lookupTables'
import EntityMixin from '@mixins/entity-mixin'
import GetsDirty from '@mixins/gets-dirty'

export var MenuSectionMixins = {
  components: {
    WithMenuSection,
    // this method of importing the component is to prevent the compiler
    // from complaining about circular dependencies (MenuSection <> MenuSectionCallout)
    MenuSection: () => import('@menu/MenuSection'),
  },
  mixins: [
    EntityMixin(ENTITY_TYPES.SECTIONS),
    GetsDirty(ENTITY_LOOKUP.MenuSection),
  ],
  props: {
    itemMods: Object,
    itemsToSave: Array,
  },
  data() {
    return {
      title: this.entity.title,
      isHovering: false,
      isHoveringOnSection: false,
      isHoveringOnDragHandle: false,
      isSelected: false,
      id: this.entity.id,
    }
  },
  computed: {
    ...mapGetters('entities', ['getEntitySet', 'getAllModsForEntity']),
    ...mapGetters('items', ['getItem', 'getItems']),
    ...mapGetters('editor', ['isShowingEditUI', 'isShowingTrashedItems']),
    ...mapGetters('layoutEditor', ['isShowingLayoutEditUI']),
    ...mapGetters('sections', ['getSections']),
    isSubsection() {
      return this.entity.parent_id
    }, // isSubsection
    itemIds: {
      get() {
        return _get(this.myMods, 'items_json', _get(this.entity, 'items_json'))
      },
      set(ids) {
        // do nothing
      },
    },
    // itemIds

    items() {
      let items = this.getItems(this.itemIds)
      return this.isShowingTrashedItems
        ? items
        : items.filter((i) => !i.isTrashed)
    }, // items

    hasDirtyItems() {
      return !!this.moddedItems.length
    }, // hasDirtyItems

    hasTrashedItems() {
      return !!this.getItems(this.itemIds).filter((i) => i.isTrashed).length
    }, // hasTrashedItems

    hasNewOrder() {
      let originalOrder = _get(this.original, 'items_json', [])
      if (this.itemIds.length !== originalOrder.length) {
        return true
      }
      return !_isEqual(originalOrder, this.itemIds)
    }, // hasNewOrder

    subsections() {
      return this.getSections(this.entity.sub_menu_sections)
    }, // items

    moddedItems() {
      return this.items.filter((i) => i.isDirty || i.isNew)
    }, // moddedItems

    sectionPadding() {
      let padding
      if (!this.isSubsection) {
        padding = !this.entity.is_callout ? 3 : 4
      }
      if (this.$mq === 'xs') {
        padding -= 1
      }
      return padding
    }, // sectionPadding
    showHoverControls() {
      return this.isHovering && this.isShowingEditUI
    }, // showHoverControls
  }, // computed

  watch: {
    hasDirtyItems(hasDirtyItems) {
      this.adjustDirtScore(hasDirtyItems)
    },
    hasTrashedItems(hasTrashedItems) {
      this.adjustDirtScore(hasTrashedItems)
    },
    hasNewOrder(hasNewOrder) {
      this.adjustDirtScore(hasNewOrder)
    },
  },

  methods: {
    ...mapActions('sections', [
      'updateSection',
      'updateSectionItems',
      'trySectionMod',
      'trySectionItemMod',
    ]),

    ...mapActions('items', ['removeItem', 'addItem', 'updateItem']),

    async removeItemAction({ sectionId, itemId }) {
      this.adjustDirtScore(true)
      this.removeItem({ sectionId, itemId })
      await this.$emit('isotopeRefresh', { sectionId })
    }, // removeItemAction

    getTagForElem(elem) {
      const lookup = {
        paragraph: 'p',
        text: 'span',
        hard_break: 'br',
        italic: 'i',
      }
      return lookup[elem]
    }, // getTagForElem

    updateSectionAction(key, val) {
      this.updateSection({ section: this.entity, key, val })
    }, // updateSectionAction

    updateItemAction(sectionId, { item, key, val }) {
      this.updateItem({ item, key, val })
    }, // updateItemAction

    async addItemAction({ sectionId, index }) {
      await this.addItem({ sectionId, index })
      await this.$emit('isotopeRefresh', { sectionId })
    }, // addSectionAfterAction

    async dragEventAction(evt) {
      let newItemSet = this.itemIds ? [...this.itemIds] : []
      let sectionId = this.entity.id
      if (evt.moved) {
        // console.log({
        //   oldIndex: evt.moved.oldIndex,
        //   newIndex: evt.moved.newIndex,
        // })
        let newOrder = arrayMove(
          newItemSet,
          evt.moved.oldIndex,
          evt.moved.newIndex
        )

        // console.log({ newOrder })
        await this.updateSectionItems({ items: newOrder, sectionId })
      }
      if (evt.added) {
        let itemId = evt.added.element
        newItemSet.splice(evt.added.newIndex, 0, itemId)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
      if (evt.removed) {
        newItemSet.splice(evt.removed.oldIndex, 1)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
    }, // dragEventAction
  },
}

export default MenuSectionMixins
