<template>
  <VLayout row wrap>
    <VFlex xs12>
      <VLayout class="menu-item--top" row>
        <BaseEditable
          v-if="entity.title"
          tag="span"
          :content="original.title"
          :mods="myMods.title"
          class="menu-item--title grow text-xs-right"
          @update="updateItem('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <VFlex v-if="JSON.parse(entity.prices).length" shrink mx-1>/</VFlex>
        <VFlex class="menu-item--prices grow text-xs-left">
          <MenuItemPrices
            :prices="JSON.parse(entity.prices)"
            :mods="priceMods"
            divider=", "
            @update="updateItem('prices', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </VFlex>
      </VLayout>
    </VFlex>
    <VFlex v-if="entity.description" class="menu-item--description" xs12 row>
      {{ entity.description }}
    </VFlex>
  </VLayout>
</template>

<script>
import MenuItemMixin from '@mixins/menu-item'

export default {
  name: 'MenuCalloutItem',
  mixins: [MenuItemMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';
.callout-item {
  .menu-item--prices {
    font-weight: normal !important;
    text-align: left !important;
  }
}
</style>
