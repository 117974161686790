<template>
  <VLayout
    v-on-clickaway="clickawayFn"
    :class="[
      'menu-item condensed',
      { hovering: isHovering },
      { 'showing-ui': isShowingEditUI },
      { selected: isSelected && isShowingEditUI },
      { 'is-dirty': entity.isNew },
      { 'delete-hover': deleteHover },
    ]"
    row
    wrap
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @click="itemClickAction"
  >
    <VFlex xs12>
      <VLayout class="menu-item--top" row justify-center>
        <VFlex class="menu-item--title shrink" text-xs-center
          ><BaseEditable
            v-if="entity.title"
            ref="item-title"
            tag="span"
            :content="original.title"
            :mods="myMods.title"
            class="span"
            @update="updateItem('title', $event)"
            @dirtyUpdate="adjustDirtScore"
          />
        </VFlex>
        <VFlex v-if="prices.length" mx-1 shrink>/</VFlex>
        <MenuItemPrices
          :prices="prices"
          :mods="priceMods"
          divider=" , "
          @update="updateItem('prices', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
        <EntityModButtons
          v-show="showHoverControls"
          show-delete
          bottom
          :hide-buttons="isSelected && !isHovering"
          :is-entity-selected="isSelected"
          @delete="$emit('deleteItem')"
          @add="$emit('addItemAfter')"
          @deleteHover="deleteHover = $event"
        />
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import MenuItemMixin from '@mixins/menu-item'

import MenuItemPrices from '@menu/MenuItemPrices'
import EntityModButtons from '@menu/EntityModButtons'

export default {
  name: 'MenuCalloutItemCondensed',
  components: {
    MenuItemPrices,
    EntityModButtons,
  },
  mixins: [clickaway, MenuItemMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';
.menu-item.condensed {
  position: relative;
  .item-price {
    font-weight: normal;
  }
}
</style>
