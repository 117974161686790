<template>
  <MenuSection
    v-bind="{ ...$attrs, ...$props }"
    :class="['menu-section callout pa-3 my-4 mb-4 text-xs-center', { isDirty }]"
  >
    <div class="menu-section--title blanch--font"
      ><img
        class="callout-title--icon"
        src="@assets/images/db-callout-logo.svg"
      />
      <BaseEditable
        v-if="entity.title"
        tag="span"
        :content="entity.title"
        :mods="myMods.title"
        @update="$emit('updateTitle', $event)"
        @dirtyUpdate="adjustDirtScore"
      />
    </div>
    <BaseEditable
      v-if="entity.section_subtitle_note"
      tag="span"
      :content="entity.section_subtitle_note"
      :mods="myMods.section_subtitle_note"
      class="menu-section--subtitle asap--font"
      @update="$emit('updateSubtitle', $event)"
      @dirtyUpdate="adjustDirtScore"
    />
    <div class="menu-section--items">
      <component
        :is="
          entity.section_type === 'condensed'
            ? 'MenuCalloutItemCondensed'
            : 'MenuCalloutItem'
        "
        v-for="(item, i) of items"
        :key="item.id"
        :ref="`item-${i}`"
        :entity="item"
        class="menu-item callout-item asap--font"
        :my-mods="itemMods[item.id] ? itemMods[item.id] : {}"
        @addItemBefore="addItemAction({ parentId: entity.id, index: i })"
        @addItemAfter="addItemAction({ parentId: entity.id, index: i + 1 })"
        @deleteItem="removeItem({ parentId: entity.id, itemId: item.id })"
        @updateItem="updateItemAction(entity.id, $event)"
        @dirtyUpdate="adjustDirtScore"
      />
    </div>
  </MenuSection>
</template>

<script>
import MenuSectionMixin from '@mixins/menu-section'

import MenuCalloutItem from '@menu/MenuCalloutItem'
import MenuCalloutItemCondensed from '@menu/MenuCalloutItemCondensed'

export default {
  name: 'MenuSectionCallout',
  components: {
    MenuCalloutItem,
    MenuCalloutItemCondensed,
  },
  mixins: [MenuSectionMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

.menu-section--title {
  font-size: 60px;
  line-height: 0.7;
}
.menu-section {
  &.callout {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    border: 4px solid $color-db-purple;
    border-radius: 6px;
    .menu-section--items {
      margin: 1em 0;
    }
    .callout-title--icon {
      height: 48px;
      margin: -3px 12px -3px 0;
    }
  }
}
</style>
