var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VLayout',_vm._b({directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.clickawayFn),expression:"clickawayFn"}],class:[
    'menu-item',
    ("type--" + _vm.sectionType),
    !_vm.isCondensed ? 'standard' : 'condensed',
    { hovering: _vm.isHovering },
    { 'showing-ui': _vm.isShowingEditUI },
    { selected: _vm.isSelected && _vm.isShowingEditUI },
    { 'is-dirty': _vm.entity.isNew },
    { trashed: _vm.isTrashed },
    { 'delete-hover': _vm.deleteHover } ],attrs:{"row":"","wrap":""},on:{"mouseover":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false},"click":_vm.itemClickAction}},"VLayout",_vm._d({},[_vm.itemMargin,true])),[_c('EntityModButtons',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHoverControls),expression:"showHoverControls"}],attrs:{"hide-buttons":_vm.isSelected && !_vm.isHovering,"is-entity-selected":_vm.isSelected},on:{"add":function($event){return _vm.$emit('addItemBefore')}}}),_c('VFlex',{attrs:{"xs12":""}},[_c('VLayout',{staticClass:"menu-item--top",attrs:{"row":""}},[_vm._t("title",[_c('MenuItemTitle',{ref:"selectedOnMountTarget",attrs:{"content":_vm.original.title,"mods":_vm.myMods.title},on:{"update":function($event){return _vm.emitItemUpdated('title', $event)},"dirtyUpdate":_vm.adjustDirtScore}})],{"emitItemUpdated":_vm.emitItemUpdated,"content":_vm.original.title,"mods":_vm.myMods.title,"dirtyUpdate":_vm.adjustDirtScore}),_vm._t("dots",[(_vm.prices.length)?_c('VFlex',{staticClass:"menu-element--dots",attrs:{"grow":"","mx-1":""}}):_vm._e()]),_vm._t("prices",[(_vm.allowPrices)?_c('MenuItemPrices',{attrs:{"prices":_vm.prices,"mods":_vm.priceMods},on:{"update":function($event){return _vm.emitItemUpdated('prices', $event)},"dirtyUpdate":_vm.adjustDirtScore}}):_vm._e()],{"prices":_vm.prices,"priceMods":_vm.priceMods,"emitItemUpdated":_vm.emitItemUpdated,"adjustDirtScore":_vm.adjustDirtScore})],2)],1),(_vm.allowDesc)?[(_vm.original.description || _vm.descMods || _vm.isSelected)?_vm._t("desc",[_c('MenuItemDescription',_vm._b({ref:"item-desc",attrs:{"content":[null, undefined].includes(_vm.original.description)
            ? ''
            : _vm.original.description,"mods":_vm.descMods,"is-item-selected":_vm.isSelected,"placeholder":_vm.descPlaceholder},on:{"update":function($event){return _vm.emitItemUpdated('description', $event)},"dirtyUpdate":_vm.adjustDirtScore,"editableDestroyed":function($event){return _vm.$emit('dirtyUpdate', false)}}},'MenuItemDescription',_vm.descProps,false))],{"original":_vm.original,"descMods":_vm.descMods,"isSelected":_vm.isSelected,"adjustDirtScore":_vm.adjustDirtScore,"emitItemUpdated":_vm.emitItemUpdated,"descProps":_vm.descProps}):_vm._e()]:_vm._e(),(_vm.addons.length || (_vm.isSelected && _vm.isShowingEditUI))?_c('BaseMenuComponent',{ref:"item-addons",attrs:{"name":"MenuItemAddons","is-item-selected":_vm.isSelected,"is-dirty":_vm.areAddonsModified,"addons":_vm.addons},on:{"newAddon":_vm.newAddonAction,"removeAddon":_vm.removeAddonAction,"updateAddon":_vm.updateAddonAction}}):_vm._e(),(_vm.allowAttrs)?_c('MenuItemAttrs',{attrs:{"attr-ids":_vm.entity.attributes,"is-dirty":_vm.areAttsModified,"is-item-selected":_vm.isSelected},on:{"selectAttribute":_vm.selectAttributeAction,"deselectAttribute":_vm.deselectAttributeAction}}):_vm._e(),_vm._t("bottom-separator"),_c('EntityModButtons',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHoverControls),expression:"showHoverControls"}],attrs:{"show-delete":"","bottom":"","hide-buttons":_vm.isSelected && !_vm.isHovering,"is-entity-selected":_vm.isSelected},on:{"deleteBtnClick":function($event){return _vm.$emit('deleteItem')},"add":function($event){return _vm.$emit('addItemAfter')},"deleteHover":function($event){_vm.deleteHover = $event}}}),_c('VLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHoverControls),expression:"showHoverControls"}],staticClass:"drag-handle--wrap"},[_c('VIcon',{staticClass:"drag-handle",attrs:{"color":"grey"}},[_vm._v("drag_indicator")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }